<template>
  <div class="an-status">
    <div class="an-title">
      <i class="el-icon-message-solid"></i>
      <span>报警状态</span>
    </div>
    <div class="an-status-con">
      <img src="../../../../../../assets/images/single/screen03/jpg_normal.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "moduleOne"
  }
</script>

<style scoped>
  .an-status {
    width: 100%;
    height: 100%;
  }
  .an-status-con {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .an-status-con img{
    width: 125px;
    height: 100px;
  }
</style>