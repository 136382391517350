import { render, staticRenderFns } from "./moduleTwo.vue?vue&type=template&id=5b2ed47a&scoped=true"
import script from "./moduleTwo.vue?vue&type=script&lang=js"
export * from "./moduleTwo.vue?vue&type=script&lang=js"
import style0 from "./moduleTwo.vue?vue&type=style&index=0&id=5b2ed47a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2ed47a",
  null
  
)

export default component.exports