<template>
  <div class="an-table">
    <c-table
        ref="table"
        :index="false"
        :data="pageData"
        :tableColumns="tableColumns"
        :stripe="true"
        :pagination="form"
        @change="pageGet"
        @rowClick="rowClick"
    >
    </c-table>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import MHandle from "@/mixins/MHandle.js";
  import Configs from  "../config/moduleThree"
  let mPage = MPage({
    pagePath: "/special/event/listPage",
    pageDelPath: "",
    pageDelsPath: "",
    pageParams: {},
  });
  let mHandle = MHandle({
    handleGetPath: "",
    handleInsertPath: "",
    handleUpdatePath: "",
    handleDownLoadPath: "",
    handleData: {},
  });
  export default {
    name: "moduleThree",
    mixins: [mPage, mHandle],
    props:{
      type:Number
    },
    data(){
      let { tableColumns } = Configs(this);
      return{
        tableColumns,
        form: {
          pageSize: 10,
          pageNum: 1,
          currentPage: 1,
          total: 0
        },
        pageData: [],
      }
    },
    created() {
      this.pageParams.type = this.type
    },
    methods:{
      rowClick(data){
        this.$parent.clickRow(data)
      }
    }
  }
</script>

<style scoped>
  .an-table{
    width: 100%;
    height: 100%;
  }
</style>