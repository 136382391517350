export default function Configs() {
  const tableColumns = [
    {
      label: "事件名称",
      prop: "name",
      align: "center",
      // minWidth: 140,
      show: true
    },
    {
      label: "事件类型",
      prop: "typeName",
      align: "center",
      // minWidth: 140,
      show: true
    },
    {
      label: "检测时间",
      prop: "suffixTime",
      align: "center",
      minWidth: 140,
      show: true
    }
  ];
  return {
    tableColumns,
  };
}
