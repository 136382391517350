<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      loading: String,
      subtext: String,
    },
    data() {
      return {

      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        this.$nextTick(() => {
          let color = ['#5470c6',
            '#91cc75',
            '#fac858',
            '#ee6666',
            '#92dbff',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc']
          let color1 = [
              '#0244ff',
            '#40db00',
            '#ffb200',
            '#ff0000',
            '#00bdff',
            '#00de75',
            '#de4200',
            '#9600d6',
            '#cf0095']
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: true,
              type:'scroll',
              top:0,
              left:60,
              pageButtonGap:20,
              orient:'horizontal',
            },
            grid: {
              top: 50,
              left: 50,
              right: 0,
              bottom: 40,
            },
            toolbox: {
              right:20,
              top:20,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            xAxis: [
              {
                type:'category',
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false
                },
                axisTick: {
                  show: true,
                },
                data: val.xaxis,
              },
            ],
            yAxis: [
              {
                name: val.unit,
                nameTextStyle: {
                  color: "#333",
                },
                scale:true,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                },
                axisTick: {
                  show: false,
                },
                axisPointer: {
                  snap: true
                }
              },
            ],
            visualMap: [],
            series: []
          };
          if (val.dataList){
            val.dataList.forEach((item,i) => {
              option.visualMap.push({
                show: false,
                dimension: 0,
                seriesIndex:i,
                pieces: [
                  {
                    lte: val.startIndex,
                    color: color[(i + 9) % 9]
                  },
                  {
                    gt: val.startIndex,
                    lte: val.endIndex,
                    color: color1[(i + 9) % 9]
                  },
                  {
                    gt: val.endIndex,
                    color: color[(i + 9) % 9]
                  },
                ]
              })
              option.series.push({
                type:'line',
                name:item.type,
                data:item.yaxis,
                symbol:'none',
                markArea: {
                  itemStyle: {
                    color: 'rgba(255, 173, 177, 0.4)'
                  },
                  data: [
                    [
                      {
                        name: '',
                        xAxis: val.xaxis[val.startIndex]
                      },
                      {
                        xAxis: val.xaxis[val.endIndex]
                      }
                    ]
                  ]
                }
              })
            })
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          self.dom = echarts.init(self.$refs.dom);
          self.dom.showLoading({
            text: '',
            color: '#1989fa',
            spinnerRadius: 10,
            lineWidth: 2,
            maskColor: 'rgba(255, 255, 255, 1)',
            zlevel: 0,
          })
          self.dom.setOption(option);
          setTimeout(() => {
            self.dom.hideLoading();
          }, 1000);
          on(window, "resize", this.resize);
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      }
    },
    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
