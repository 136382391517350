<template>
  <div class="an-statistics">
    <div class="an-search-bj">
      <div class="an-search">
        <el-form style="width: 100%" ref="form" :model="form" label-width="100px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item  label="全过程时间段" prop="allDate">
                <el-date-picker
                    size="small"
                    v-model="form.allDate"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    :clearable="false"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="发生时间段" prop="date">
                <el-date-picker
                    size="small"
                    v-model="form.date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    :clearable="false"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-for="(sensor,s) in sensorList" :key="s">
              <el-form-item :label="sensor.sensorName" prop="pointList" class="label-height">
                <el-checkbox-group v-model="form.pointIdList">
                  <el-checkbox v-for="(item,i) in sensor.pointList" :key="i" :label="item.id">{{item.subName}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="form-btn">
        <el-button class="btn-primary" size="small" @click="search">查询</el-button>
        <el-button class="btn-primary" size="small" @click="goto">添加事件</el-button>
      </div>
    </div>
    <div class="sta-list">
      <div class="sta-con" v-for="(item,i) in list" :key="i">
        <div class="sta-title">
          <div class="an-title">
            <i class="el-icon-data-line"></i>
            <span>{{item.title}}</span>
          </div>
        </div>
        <chart-one :value="item"></chart-one>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="添加事件"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="1100px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form style="width: 100%" ref="form1" :model="addForm.model" :rules="addForm.rules" label-width="120px">
            <el-row :gutter="20" class="w-full">
              <el-col :span="24">
                <el-form-item  label="事件名称" prop="name">
                  <el-input size="small" v-model="addForm.model.name" placeholder="请输入事件名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item  label="全过程时间段" prop="allDate">
                  <el-date-picker
                      size="small"
                      v-model="addForm.model.allDate"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      :clearable="false"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item  label="发生时间段" prop="date">
                  <el-date-picker
                      size="small"
                      v-model="addForm.model.date"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      :clearable="false"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-for="(sensor,s) in allSensorList" :key="s">
                <el-form-item :label="sensor.sensorName" prop="pointList" class="label-height">
                  <el-checkbox-group v-model="addForm.model.pointList">
                    <el-checkbox v-for="(item,i) in sensor.pointList" :key="i" :label="item.id">{{item.subName}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form1')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import chartOne from "../chart/chart01"
  export default {
    name: "moduleFour",
    props:{
      // list:Array,
      type:Number
    },
    components:{chartOne},
    data(){
      return{
        sensorList:[],
        allSensorList:[],
        form:{
          pointIdList:[],
          allDate:[],
          date:[]
        },
        dialogVisible: false,
        addForm:{
          model:{},
          rules:{
            name:[
              { required: true, message: '请填写事件名称', trigger: 'blur' }
            ],
            allDate:[
              { required: true, message: '请选择全过程时间段', trigger: 'blur' }
            ],
            date:[
              { required: true, message: '请选择发生时间段', trigger: 'blur' }
            ],
            pointList:[
              { required: true, message: '请选择测点', trigger: 'blur' }
            ]
          }
        },
        list:[
          {
            dataList:[],
            endIndex:0,
            startIndex:0,
            title:'',
            unit:'',
            xaxis:[]
          }
        ]
      }
    },
    mounted() {
      let time = this.$moment(new Date()).format('X').valueOf()
      let start = this.$moment(new Date()).format('X').valueOf() - 60
      let prefixTime = this.$moment(new Date()).format('X').valueOf() - 40
      let suffixTime = this.$moment(new Date()).format('X').valueOf() - 20
      this.$set(this.form.allDate,0,this.$moment.unix(parseInt(start)).format('YYYY-MM-DD HH:mm:ss'))
      this.$set(this.form.allDate,1,this.$moment.unix(parseInt(time)).format('YYYY-MM-DD HH:mm:ss'))
      this.$set(this.form.date,0,this.$moment.unix(parseInt(prefixTime)).format('YYYY-MM-DD HH:mm:ss'))
      this.$set(this.form.date,1,this.$moment.unix(parseInt(suffixTime)).format('YYYY-MM-DD HH:mm:ss'))
      // this.$parent.getIconList(this.form.allDate[1])
      this.getSensor()
    },
    methods:{
      getSensor(){
        this.$http.get('/special/event/analysis/sensor/type',{params:{type:this.type}}).then(res => {
          if (res.success){
            if (res.data){
              this.sensorList = res.data
              this.allSensorList = res.data
              this.form.pointIdList = [res.data[0].pointList[0].id]
              this.search()
            }
          }
        })
      },
      getSensorById(data){
        this.$http.get('/special/event/analysis/sensor/event',{params:{id:data.id}}).then(res => {
          if (res.success){
            if (res.data){
              this.sensorList = res.data
              this.form.pointIdList = [res.data[0].pointList[0].id]
              this.$set(this.form.allDate,0,data.prefixTime)
              this.$set(this.form.allDate,1,data.suffixTime)
              this.$set(this.form.date,0,data.startTime)
              this.$set(this.form.date,1,data.endTime)
              this.search()
            }
          }
        })
      },
      search(){
        let s1 = this.$moment(this.form.allDate[0]).format('X').valueOf()
        let e1 = this.$moment(this.form.allDate[1]).format('X').valueOf()
        let s2 = this.$moment(this.form.date[0]).format('X').valueOf()
        let e2 = this.$moment(this.form.date[1]).format('X').valueOf()
        if (!(s1 < s2 && e1 > e2)){
          this.$message.error('全过程时间段应包含发生时间段')
          return
        }
        if (this.form.pointIdList.length === 0){
          this.$message.error('请选择测点')
          return
        }
        this.form.prefixTime = this.form.allDate[0]
        this.form.suffixTime = this.form.allDate[1]
        this.form.startTime = this.form.date[0]
        this.form.endTime = this.form.date[1]
        this.$parent.getIconList(this.form.allDate[1])
        this.$http.post('/special/event/analysis/overall/process',this.form).then(res => {
          if (res.success){
            if (res.data){
              this.list = res.data
            }
          }
        })
      },
      goto() {
        this.addForm.model = {
          allDate:[],
          date:[],
          pointList:[],
          type:''
        }
        this.dialogVisible = true;
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let s1 = this.$moment(this.addForm.model.allDate[0]).format('X').valueOf()
            let e1 = this.$moment(this.addForm.model.allDate[1]).format('X').valueOf()
            let s2 = this.$moment(this.addForm.model.date[0]).format('X').valueOf()
            let e2 = this.$moment(this.addForm.model.date[1]).format('X').valueOf()
            if (!(s1 < s2 && e1 > e2)){
              this.$message.error('全过程时间段应包含发生时间段')
              return
            }
            this.addForm.model.prefixTime = this.addForm.model.allDate[0]
            this.addForm.model.suffixTime = this.addForm.model.allDate[1]
            this.addForm.model.startTime = this.addForm.model.date[0]
            this.addForm.model.endTime = this.addForm.model.date[1]
            this.addForm.model.type = this.type
            this.$http.post("/special/event/save",this.addForm.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.$parent.pageGet(true)
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      beforeDialogClose(done) {
        done();
      },
      resetFields() {
        this.dialogVisible = false;
      },
    }
  }
</script>

<style scoped>
  .an-statistics {
    width: 100%;
    height: 100%;
  }
  .an-search-bj{
    width: 100%;
    height: 200px;
    padding-bottom: 40px;
    position: relative;
    background: #fff;
  }
  .an-search{
    width: 100%;
    height: 100%;
    padding: 20px 20px 5px;
    overflow-y: auto;
  }
  .form-btn{
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: flex-end;
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .sensor-list{
    width: 100%;
    height: auto;
    display: flex;
  }
  .sensor-label{
    width: 120px;
    text-align: right;
    line-height: 20px;
  }
  .sta-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .sta-list{
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 210px);
    overflow-y: auto;
  }
  .an-search::-webkit-scrollbar,.sta-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }
  .an-search::-webkit-scrollbar-track,.sta-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .an-search::-webkit-scrollbar-thumb,.sta-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999;
  }
  .sta-con{
    margin-bottom: 10px;
    width: 100%;
    height: 350px;
    background: #fff;
    padding: 20px;
  }
  .sta-con:last-child{
    margin-bottom: 0;
  }
  /*/deep/ .el-range-editor--small.el-input__inner{*/
  /*  width: 600px;*/
  /*}*/
  /deep/ .el-form-item{
    margin-bottom: 5px;
  }
  /deep/ .el-checkbox-group{
    line-height: 25px;
  }
  /deep/ .el-checkbox{
    margin-right: 10px;
  }
  .label-height /deep/ .el-form-item__label{
    line-height: 25px;
  }
</style>