<template>
  <div class="an-data">
    <div class="an-data-list" v-if="show">
      <div v-for="(item,i) in array" :key="i" :style="'width:' + (100 / array.length) + '%'">
        <div :class="item.name === '风速' ? 'an-data-con fengsu' : 'an-data-con'">
          <img :src="require('../../../../../../assets/images/single/analysis/' + item.icon + '.png')" alt="">
          <div class="an-d-tit">{{item.name}}</div>
          <div class="an-d-data">{{item.value}}{{item.unit}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "moduleTwo",
    props:{
      type:Number
    },
    data(){
      return{
        list:[
          {name:'风速',type:'windSpeed',unit:'',value:null,icon:'fengsu'},
          {name:'风向',type:'windDirection',unit:'',value:null,icon:'fengxiang'},
          {name:'风攻角',type:'windAngle',unit:'',value:null,icon:'fengjiaodu'},
          {name:'风偏角',type:'windAngleOther',unit:'',value:null,icon:'fengjiaodu'},
          {name:'紊流度',type:'turbulence',unit:'',value:null,icon:'wenliudu'},
          {name:'振动能量因子',type:'energyFactor',unit:'',value:null,icon:'nengliangbi'},
          {name:'挠度最大值',type:'deflectionMax',unit:'',value:null,icon:'naodu'},
          {name:'振动x方向最大值',type:'vibrationMaxX',unit:'',value:null,icon:'x'},
          {name:'振动y方向最大值',type:'vibrationMaxY',unit:'',value:null,icon:'y'},
          {name:'振动z方向最大值',type:'vibrationMaxZ',unit:'',value:null,icon:'z'},
          {name:'位移最大值',type:'mss',unit:'',value:null,icon:'weiyi'},
          {name:'应力最大值',type:'rsg',unit:'',value:null,icon:'yinli'},
          {name:'索振动',type:'cableVibration',unit:'',value:null,icon:'zhendong'}
        ],
        array:[],
        show:false
      }
    },
    methods:{
      getIconList(time){
        this.$http.post('/special/event/analysis/specific/item',{
          type:this.type,
          dateTime:time
        }).then(res => {
          if (res.success){
            if (res.data){
              this.array = []
              for (let arr of this.list){
                for (let item in res.data){
                  if (arr.type === item){
                    if (res.data[item].value === null) {
                      arr.value = '--'
                    } else {
                      arr.value = res.data[item].value
                    }
                    arr.unit = res.data[item].unit
                  }
                }
                if (arr.value !== null){
                  this.array.push(arr)
                }
              }
            }
          }
          this.show = true
        })
      }
    }
  }
</script>

<style scoped>
  .an-data {
    width: 100%;
    height: 100%;
  }
  .an-data-list{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .an-data-con{
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 20px;
  }
  .an-data-con img{
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .fengsu img{
    animation: rot 2s linear infinite;
  }
  .an-d-tit{
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
  }
  .an-d-data{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
</style>